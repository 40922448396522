export const COURT_COSTS = 109.50;
export const CONVENIENCE_FEE = .024;

//export const COURT_COSTS = 0;
//export const CONVENIENCE_FEE = 0;


export const DA_ADDRESS = 'Po Box 728';
export const DA_CITY_STATE = 'Olathe, KS';
export const DA_ZIP = '66051';
export const DA_EMAIL = 'DATTrafficDiversionUnit@jocogov.org';
export const DA_PHONE = '913-715-3011';


export const SSO_ERR_CODE = [
    { code: 300, desc: 'Customer session with partner system terminated or expired' },
    { code: 301, desc: 'SSO request contains an invalid timestamp.' },
    { code: 302, desc: 'SSO request contains an invalid signature.' },
    { code: 304, desc: 'One or more of the required / mandatory parameters sent as part of SSO request are invalid or not present.' },
    { code: 306, desc: 'SSO request contains an invalid Partner Key.' },
    { code: 402, desc: 'SSO request contains an invalid Partner Customer Session ID.' },
    { code: 403, desc: 'SSO request contains an invalid Customer Account Number' },
    { code: 404, desc: 'SSO request contains an invalid SSN.' },
    { code: 405, desc: 'SSO request contains an invalid First name(Maximum allowable length exceeded).' },
    { code: 406, desc: 'SSO request contains an invalid Last name(Maximum allowable length exceeded).' },
    { code: 407, desc: 'SSO request contains an invalid middle name(Maximum allowable length exceeded).' },
    { code: 408, desc: 'SSO request contains an invalid data in Address Line1 field(Maximum allowable length exceeded).' },
    { code: 409, desc: 'SSO request contains an invalid data in Address Line1 field(Maximum allowable length exceeded).' },
    { code: 410, desc: 'SSO request contains an invalid data in the City field(Maximum allowable length exceeded).' },
    { code: 411, desc: 'SSO request contains an invalid state data(Maximum allowable length exceeded).' },
    { code: 412, desc: 'SSO request contains an invalid Zip1 data(Maximum allowable length exceeded).' },
    { code: 413, desc: 'SSO request contains an invalid Zip2 data(Maximum allowable length exceeded).' },
    { code: 414, desc: 'SSO request contains an invalid Country code parameter(Maximum allowable length exceeded).' },
    { code: 415, desc: 'SSO request contains an invalid Home Phone parameter(Maximum allowable length exceeded).' },
    { code: 416, desc: 'SSO request contains an invalid Cell Phone parameter(Maximum allowable length exceeded).' },
    { code: 417, desc: 'SSO request contains an invalid Work Phone parameter(Maximum allowable length exceeded).' },
    { code: 418, desc: 'SSO request contains an invalid Primary email parameter(Maximum allowable length exceeded).' },
    { code: 419, desc: 'SSO request contains an invalid DOB parameter in an invalid format.' },
    { code: 420, desc: 'SSO request contains an invalid Payment Due date parameter in an invalid format.' },
    { code: 421, desc: 'SSO request contains invalid current balance' },
    { code: 422, desc: 'SSO request contains invalid current statement balance.' },
    { code: 423, desc: 'SSO request contains invalid min payment due' },
    { code: 424, desc: 'SSO request contains invalid credit line' },
    { code: 431, desc: 'SSO request contains invalid date into collection.' },
    { code: 432, desc: 'SSO request contains invalid account type.' },
    { code: 434, desc: 'SSO request contains invalid account subtype.' },
    { code: 550, desc: 'Invalid Channel ID received.' },
    { code: 554, desc: 'Invalid or Missing User ID received when Channel is being passed as 25(Simple Agent)' },
    { code: 576, desc: 'SSO request contains invalid language indicator.' },
    { code: 579, desc: 'SSO request contains an invalid customer type.' },
    { code: 1075, desc: 'SSO invalid base URL' },
    { code: 1076, desc: 'SSO request contains invalid Login Reference Id.' },
    { code: 1077, desc: 'SSO request contains invalid access flag value' }
]