import toastr from 'toastr';
import Cleave from 'cleave.js/dist/cleave-esm';
import cleaveAddon from 'cleave.js/dist/addons/cleave-phone.us';
import * as EmailValidator from 'email-validator'


import { COURT_COSTS, CONVENIENCE_FEE, DA_ADDRESS, DA_CITY_STATE, DA_ZIP, DA_EMAIL, DA_PHONE } from './constants';

const uri = 'api/Diversion';
const currentURL = window.location.href;
let reqURL;

const browser = {};
if (/(Edge\/[0-9]{2})/i.test(navigator.userAgent)) {
	browser.agent = navigator.userAgent.match(/(Edge\/[0-9]{2})/i)[0].split("/")[0];
	browser.version = parseInt(navigator.userAgent.match(/(Edge\/[0-9]{2})/i)[0].split("/")[1]);
} else if (/(chrome\/[0-9]{2})/i.test(navigator.userAgent)) {
	browser.agent = navigator.userAgent.match(/(chrome\/[0-9]{2})/i)[0].split("/")[0];
	browser.version = parseInt(navigator.userAgent.match(/(chrome\/[0-9]{2})/i)[0].split("/")[1]);
} else if (/(firefox\/[0-9]{2})/i.test(navigator.userAgent)) {
	browser.agent = navigator.userAgent.match(/(firefox\/[0-9]{2})/i)[0].split("/")[0];
	browser.version = parseInt(navigator.userAgent.match(/(firefox\/[0-9]{2})/i)[0].split("/")[1]);
} else if (/(MSIE\ [0-9]{1})/i.test(navigator.userAgent)) {
	browser.agent = navigator.userAgent.match(/(MSIE\ [0-9]{1})/i)[0].split(" ")[0];
	browser.version = parseInt(navigator.userAgent.match(/(MSIE\ [0-9]+)/i)[0].split(" ")[1]);
} else if (/(Opera\/[0-9]{1})/i.test(navigator.userAgent)) {
	browser.agent = navigator.userAgent.match(/(Opera\/[0-9]{1})/i)[0].split("/")[0];
	browser.version = parseInt(navigator.userAgent.match(/(Opera\/[0-9]{1})/i)[0].split("/")[1]);
} else if (/(Trident\/[7]{1})/i.test(navigator.userAgent)) {
	browser.agent = "MSIE";
	browser.version = 11;
} else {
	browser.agent = false;
	browser.version = false;
}

if (/(Windows\ NT\ [0-9]{1}\.[0-9]{1})/.test(navigator.userAgent)) {
	browser.os = "Windows";

	switch (parseFloat(navigator.userAgent.match(/(Windows\ NT\ [0-9]{1}\.[0-9]{1})/)[0].split(" ")[2])) {
		case 6.0:
			browser.osversion = "Vista";
			break;
		case 6.1:
			browser.osversion = "7";
			break;
		case 6.2:
			browser.osversion = "8";
			break;
		default:
			browser.osversion = false;
	}
} else if (/(OS\ X\ [0-9]{2}\.[0-9]{1})/.test(navigator.userAgent)) {
	browser.os = "OS X";
	browser.osversion = navigator.userAgent.match(/(OS\ X\ [0-9]{2}\.[0-9]{1})/)[0].split(" ")[2];
} else if (/(Linux)/.test(navigator.userAgent)) {
	browser.os = "Linux";
	browser.osversion = false;
}

if (browser.agent === "MSIE") {
	document.getElementById('main-content-IE').classList.remove('d-none')
	document.getElementById('main-content').classList.add('d-none')
} else {
	document.getElementById('main-content-IE').classList.add('d-none')
	document.getElementById('overviewSection').classList.add('d-flex')
	document.getElementById('main-content').classList.remove('d-none')
}

if (currentURL !== 'http://localhost:44307/' && currentURL !== 'https://localhost:44307/') {
	reqURL = 'onlinetrafficdiversion/' + uri;
} else {
	reqURL = uri;
}

toastr.options = {
	"closeButton": false,
	"debug": true,
	"newestOnTop": false,
	"progressBar": false,
	"positionClass": "toast-top-full-width",
	"preventDuplicates": false,
	"onclick": null,
	"showDuration": "300",
	"hideDuration": "1000",
	"timeOut": "5000",
	"extendedTimeOut": "1000",
	"showEasing": "swing",
	"hideEasing": "linear",
	"showMethod": "fadeIn",
	"hideMethod": "fadeOut"
}

const newTDA = {
	defName: "",
	caseNo: "",
	divMonths: "",
	charges: [],
	lastKnownAddress: "",
	courtDate: "",
	diversionFee: "",
	clerkFee: ""
}

const cleave = new Cleave('.input-phone', {
	phone: true,
	phoneRegionCode: 'US'
})

const personCellCleave = new Cleave('.input-phone-cell', {
	phone: true,
	phoneRegionCode: 'US'
})

const personWorkCleave = new Cleave('.input-phone-work', {
	phone: true,
	phoneRegionCode: 'US'
})

const personHomeCleave = new Cleave('.input-phone-home', {
	phone: true,
	phoneRegionCode: 'US'
})



// Hidden fields containing data from calls to web methods
const diversionData = document.getElementById('divData');
const searchData = document.getElementById('searchData');
const diversionID = document.getElementById('diversionID');
const sessionID = document.getElementById('sessionID');

// Input fields for all of the data
const searchCaseNumber = document.getElementById('caseNumber');
const searchCitationNumber = document.getElementById('citationNumber');
const searchDriversLicenseNumber = document.getElementById('driversLicense');
const searchFirstName = document.getElementById('firstName');
const searchLastName = document.getElementById('lastName');
const confirmName = document.getElementById('name');
const confirmDob = document.getElementById('dob');
const confirmPersonDriversLicenseNumber = document.getElementById('personDriversLicenseNumber');
const confirmPersonDriversLicenseState = document.getElementById('personDriversLicenseState');
const confirmPersonCitationNumber = document.getElementById('personCitationNumber');
const confirmPersonCitationDate = document.getElementById('personCitationDate');
const personPhone = document.getElementById('personPhoneNumber');
const personEmail = document.getElementById('personEmail');

// 3 questions radio buttons
const isDefendantQuestionYes = document.getElementById('isDefendantQuestionYes');
const isDefendantQuestionNo = document.getElementById('isDefendantQuestionNo');
const cdlQuestionNo = document.getElementById('duiQuestionNo');
const cdlQuestionYes = document.getElementById('duiQuestionYes');
const otherJusrisdictionQuestionNo = document.getElementById('otherJusrisdictionQuestionNo');
const otherJusrisdictionQuestionYes = document.getElementById('otherJusrisdictionQuestionYes');

// Spans for the diversion agreement
const caseNo = document.getElementById('caseNo');
const defendantName = document.getElementById('defendantName');
const effectiveDate = document.getElementById('effectiveDate');
const diversionMonths = document.getElementById('diversionMonths');
const chargeList = document.getElementById('chargeList');
const diversionFee = document.getElementById('diversionFee');
const courtCosts = document.getElementById('courtCosts');
const conditions = document.getElementById('proofOfCompletion');
const lastKnownAddress = document.getElementById('lastKnownAddress');

// Buttons
const applyDiversionBtn = document.getElementById('btnApplyDiversion');
const requiredItemsAckBtn = document.getElementById('btnRequiredItemsAck');
const startBtn = document.getElementById('btnStart');
const searchBtn = document.getElementById('btnSearch');
const personConfirmYesBtn = document.getElementById('btnPersonConfirmYes');
const personConfirmNoBtn = document.getElementById('btnPersonConfirmNo');
const ackErrorBtn = document.getElementById('btnAckError');
const acceptFeesnBtn = document.getElementById('btnAcceptFees');
const acknowledgementsBtn = document.getElementById('btnAcknowledgements');
const acceptDiversionBtn = document.getElementById('btnAcceptDiversion');
const refuseDiversionBtn = document.getElementById('btnRefuseDiversion');
const payBalanceBtn = document.getElementById('btnPayBalance');

// Sections
const overviewSection = document.querySelector('#overviewSection');
const requiredInformationSection = document.getElementById('requiredInformationSection');
const searchSection = document.getElementById('searchSection');
const confirmPersonSection = document.getElementById('confirmPersonSection');
const questionsSection = document.getElementById('questionsSection');
const callTrafficUnitSection = document.getElementById('callTrafficUnitSection');
const signAcceptSection = document.getElementById('signAcceptSection');
const acknowlegementsSection = document.getElementById('acknowlegementsSection');
const topInfoSection = document.getElementById('top-info');
const diversionAgreementSection = document.getElementById('diversionAgreementSection');
const paymentInformationSection = document.getElementById('paymentInformation');

const trafficDiversionContactInfo = document.getElementById('trafficDiversionContactInfo');
const returnedErrorMessage = document.getElementById('returnedErrorMessage');

window.addEventListener('DOMContentLoaded', e => {
	e.preventDefault();

	document.getElementById('daPhone1').innerText = DA_PHONE;
	document.getElementById('daPhone2').innerText = DA_PHONE;
	document.getElementById('daPhone3').innerText = DA_PHONE;
	document.getElementById('daPhone4').innerText = DA_PHONE;
	document.getElementById('daEmail').innerHTML = `<a href="mailto:${DA_EMAIL}">${DA_EMAIL}</a>`;
	document.getElementById('daEmail1').innerHTML = `<a href="mailto:${DA_EMAIL}">${DA_EMAIL}</a>`;
});

// Start the application process
startBtn.addEventListener('click', (e) => {
	e.preventDefault();

	topInfoSection.classList.remove('d-none');

	overviewSection.classList.remove('d-flex');
	overviewSection.classList.add('d-none');

	requiredInformationSection.classList.remove('d-none');
	requiredInformationSection.classList.add('d-flex');

	acknowlegementsSection.classList.remove('d-flex');
	acknowlegementsSection.classList.add('d-none');

	questionsSection.classList.remove('d-flex');
	questionsSection.classList.add('d-none');

	searchSection.classList.remove('d-flex');
	searchSection.classList.add('d-none');

	confirmPersonSection.classList.remove('d-flex');
	confirmPersonSection.classList.add('d-none');

	callTrafficUnitSection.classList.remove('d-flex');
	callTrafficUnitSection.classList.add('d-none');

	signAcceptSection.classList.remove('d-flex');
	signAcceptSection.classList.add('d-none');

	diversionAgreementSection.classList.remove('d-flex');
	diversionAgreementSection.classList.add('d-none');

	paymentInformationSection.classList.remove('d-flex');
	paymentInformationSection.classList.add('d-none');
});

// Go to the search step
requiredItemsAckBtn.addEventListener('click', (e) => {
	e.preventDefault();

	topInfoSection.classList.remove('d-none');

	overviewSection.classList.remove('d-flex');
	overviewSection.classList.add('d-none');

	requiredInformationSection.classList.remove('d-flex');
	requiredInformationSection.classList.add('d-none');

	acknowlegementsSection.classList.remove('d-none');
	acknowlegementsSection.classList.add('d-flex');

	questionsSection.classList.remove('d-flex');
	questionsSection.classList.add('d-none');

	searchSection.classList.remove('d-flex');
	searchSection.classList.add('d-none');

	confirmPersonSection.classList.remove('d-flex');
	confirmPersonSection.classList.add('d-none');

	callTrafficUnitSection.classList.remove('d-flex');
	callTrafficUnitSection.classList.add('d-none');

	signAcceptSection.classList.remove('d-flex');
	signAcceptSection.classList.add('d-none');

	diversionAgreementSection.classList.remove('d-flex');
	diversionAgreementSection.classList.add('d-none');

	paymentInformationSection.classList.remove('d-flex');
	paymentInformationSection.classList.add('d-none');
});

// Accepted acknowledgements, go to 3 questions
acknowledgementsBtn.addEventListener('click', (e) => {
	e.preventDefault();

	topInfoSection.classList.remove('d-none');

	overviewSection.classList.remove('d-flex');
	overviewSection.classList.add('d-none');

	requiredInformationSection.classList.remove('d-flex');
	requiredInformationSection.classList.add('d-none');

	acknowlegementsSection.classList.remove('d-flex');
	acknowlegementsSection.classList.add('d-none');

	questionsSection.classList.remove('d-none');
	questionsSection.classList.add('d-flex');

	searchSection.classList.remove('d-flex');
	searchSection.classList.add('d-none');

	confirmPersonSection.classList.remove('d-flex');
	confirmPersonSection.classList.add('d-none');

	callTrafficUnitSection.classList.remove('d-flex');
	callTrafficUnitSection.classList.add('d-none');

	signAcceptSection.classList.remove('d-flex');
	signAcceptSection.classList.add('d-none');

	diversionAgreementSection.classList.remove('d-flex');
	diversionAgreementSection.classList.add('d-none');

	paymentInformationSection.classList.remove('d-flex');
	paymentInformationSection.classList.add('d-none');
});

// Collect answers to the 3 questions and move forward
applyDiversionBtn.addEventListener('click', (e) => {
	e.preventDefault();

	// Get the checked value of the radio buttons that allows defendant to apply for diversion
	const isDefendantAnswer = isDefendantQuestionYes.checked;
	const duiAnswer = cdlQuestionNo.checked;
	const otherJurisdictionAnswer = otherJusrisdictionQuestionNo.checked;

	if (isDefendantAnswer && duiAnswer && otherJurisdictionAnswer) {
		topInfoSection.classList.remove('d-none');

		overviewSection.classList.remove('d-flex');
		overviewSection.classList.add('d-none');

		requiredInformationSection.classList.remove('d-flex');
		requiredInformationSection.classList.add('d-none');

		questionsSection.classList.remove('d-flex');
		questionsSection.classList.add('d-none');

		searchSection.classList.remove('d-none');
		searchSection.classList.add('d-flex');

		confirmPersonSection.classList.remove('d-flex');
		confirmPersonSection.classList.add('d-none');

		callTrafficUnitSection.classList.remove('d-flex');
		callTrafficUnitSection.classList.add('d-none');

		signAcceptSection.classList.remove('d-flex');
		signAcceptSection.classList.add('d-none');

		diversionAgreementSection.classList.remove('d-flex');
		diversionAgreementSection.classList.add('d-none');

		paymentInformationSection.classList.remove('d-flex');
		paymentInformationSection.classList.add('d-none');

		return;
	}

	if (!duiAnswer) {
		returnedErrorMessage.innerText = 'Because you have a CDL, federal regulations prohibit traffic diversion.';
		trafficDiversionContactInfo.classList.add('d-none');
		returnedErrorMessage.classList.remove('d-none');
	} else {
		returnedErrorMessage.innerText = '';
		trafficDiversionContactInfo.classList.remove('d-none');
		returnedErrorMessage.classList.add('d-none');
    }

	topInfoSection.classList.remove('d-none');

	overviewSection.classList.remove('d-flex');
	overviewSection.classList.add('d-none');

	requiredInformationSection.classList.remove('d-flex');
	requiredInformationSection.classList.add('d-none');

	questionsSection.classList.remove('d-flex');
	questionsSection.classList.add('d-none');

	searchSection.classList.remove('d-flex');
	searchSection.classList.add('d-none');

	confirmPersonSection.classList.remove('d-flex');
	confirmPersonSection.classList.add('d-none');

	callTrafficUnitSection.classList.remove('d-none');
	callTrafficUnitSection.classList.add('d-flex');

	signAcceptSection.classList.remove('d-flex');
	signAcceptSection.classList.add('d-none');

	diversionAgreementSection.classList.remove('d-flex');
	diversionAgreementSection.classList.add('d-none');

	paymentInformationSection.classList.remove('d-flex');
	paymentInformationSection.classList.add('d-none');
});

//Show confirm person section
searchBtn.addEventListener('click', (e) => {
	e.preventDefault();

	const caseNo = searchCaseNumber.value;
	const citationNo = searchCitationNumber.value;
	const driverLicenseNo = searchDriversLicenseNumber.value;
	const firstName = searchFirstName.value;
	const lastName = searchLastName.value;

	if (caseNo !== '' || citationNo !== '' || driverLicenseNo !== '' || (lastName !== '' && firstName !== '')) {
		// Perform the search
		$.ajax({
			url: reqURL + '/SearchForCase',
			type: 'get',
			contentType: '',
			dataType: 'json',
			data: {
				caseNumber: caseNo,
				citationNumber: citationNo,
				driversLicenseNumber: driverLicenseNo,
				firstName: firstName,
				lastName: lastName
			},
			success: (data, status, xhr) => {
				if (data !== null) {
					searchData.value = data.DiversionData;

					if (data.ErrorMessage !== '') {

						if (data.DisplayCallDAMessage === true) {
							trafficDiversionContactInfo.classList.remove('d-none');
						} else {
							trafficDiversionContactInfo.classList.add('d-none');
						}

						returnedErrorMessage.classList.remove('d-none');
						returnedErrorMessage.innerHTML = data.ErrorMessage;

						// Pull information from data and put it into the confirm person section fields.
						confirmName.value = `${data.FirstName} ${data.LastName}`;
						confirmDob.value = moment(data.DOB).format('M/D/YYYY');
						confirmPersonDriversLicenseNumber.value = data.DriversLicenseNumber;
						confirmPersonDriversLicenseState.value = data.DriversLicenseState;
						confirmPersonCitationNumber.value = data.CitationNumber;
						confirmPersonCitationDate.value = moment(data.CitationDate).format('M/D/YYYY');

						topInfoSection.classList.remove('d-none');

						overviewSection.classList.remove('d-flex');
						overviewSection.classList.add('d-none');

						requiredInformationSection.classList.remove('d-flex');
						requiredInformationSection.classList.add('d-none');

						questionsSection.classList.remove('d-flex');
						questionsSection.classList.add('d-none');

						searchSection.classList.remove('d-flex');
						searchSection.classList.add('d-none');

						confirmPersonSection.classList.remove('d-flex');
						confirmPersonSection.classList.add('d-none');

						callTrafficUnitSection.classList.remove('d-none');
						callTrafficUnitSection.classList.add('d-flex');

						signAcceptSection.classList.remove('d-flex');
						signAcceptSection.classList.add('d-none');

						diversionAgreementSection.classList.remove('d-flex');
						diversionAgreementSection.classList.add('d-none');

						paymentInformationSection.classList.remove('d-flex');
						paymentInformationSection.classList.add('d-none');
					} else {
						// Pull information from data and put it into the confirm person section fields.
						confirmName.value = `${data.FirstName} ${data.LastName}`;
						confirmDob.value = moment(data.DOB).format('M/D/YYYY');
						confirmPersonDriversLicenseNumber.value = data.DriversLicenseNumber;
						confirmPersonDriversLicenseState.value = data.DriversLicenseState;
						confirmPersonCitationNumber.value = data.CitationNumber;
						confirmPersonCitationDate.value = moment(data.CitationDate).format('M/D/YYYY');

						topInfoSection.classList.remove('d-none');

						overviewSection.classList.remove('d-flex');
						overviewSection.classList.add('d-none');

						requiredInformationSection.classList.remove('d-flex');
						requiredInformationSection.classList.add('d-none');

						questionsSection.classList.remove('d-flex');
						questionsSection.classList.add('d-none');

						searchSection.classList.remove('d-flex');
						searchSection.classList.add('d-none');

						confirmPersonSection.classList.remove('d-none');
						confirmPersonSection.classList.add('d-flex');

						callTrafficUnitSection.classList.remove('d-flex');
						callTrafficUnitSection.classList.add('d-none');

						signAcceptSection.classList.remove('d-flex');
						signAcceptSection.classList.add('d-none');

						diversionAgreementSection.classList.remove('d-flex');
						diversionAgreementSection.classList.add('d-none');

						paymentInformationSection.classList.remove('d-flex');
						paymentInformationSection.classList.add('d-none');
					}
				} else {
					topInfoSection.classList.remove('d-none');

					overviewSection.classList.remove('d-flex');
					overviewSection.classList.add('d-none');

					requiredInformationSection.classList.remove('d-flex');
					requiredInformationSection.classList.add('d-none');

					questionsSection.classList.remove('d-flex');
					questionsSection.classList.add('d-none');

					searchSection.classList.remove('d-flex');
					searchSection.classList.add('d-none');

					confirmPersonSection.classList.remove('d-none');
					confirmPersonSection.classList.add('d-flex');

					callTrafficUnitSection.classList.remove('d-none');
					callTrafficUnitSection.classList.add('d-flex');

					signAcceptSection.classList.remove('d-flex');
					signAcceptSection.classList.add('d-none');

					diversionAgreementSection.classList.remove('d-flex');
					diversionAgreementSection.classList.add('d-none');

					paymentInformationSection.classList.remove('d-flex');
					paymentInformationSection.classList.add('d-none');
				}
			},
			error: (jqXHR, textStatus, errorThrown) => {
				console.log(jqXHR);
				toastr.error(`The following error occurred: ${errorThrown}`);
			}
		}).then(() => {

		});
	} else {
		toastr.error('You must provide information to search for.');
	}
})

//Show the invoice section
personConfirmYesBtn.addEventListener('click', (e) => {
	e.preventDefault();

	const phone = cleave.getRawValue();
	const email = personEmail.value;
	const isValidEmailAddress = EmailValidator.validate(email);

	if (!isValidEmailAddress || phone === null || phone === '') {
		toastr.error('You must provide a phone number and an email address in order to continue.')
		return;
	} else {
		const searchResults = searchData.value.split('|');

		createDiversionRecord(searchResults[2], searchResults[10], email, phone)
			.then(res => {
				const returnData = res.split('|');
				if (returnData[0] !== '') {
					toastr.error(`The following error occurred: ${returnData[0]}`);
				} else {
					diversionID.value = res

					let diversionData = {
						errorMessage: searchResults[0],
						contactDaflg: searchResults[1],
						case: searchResults[2],
						citation: searchResults[3],
						dl: searchResults[4],
						lname: searchResults[5],
						fname: searchResults[6],
						dob: searchResults[7],
						dlstate: searchResults[8],
						citationdate: searchResults[9],
						divfee: searchResults[10],
						courtcosts: searchResults[11]
					};

					const receiptDetails = document.getElementById('receiptDetails');
					receiptDetails.innerHTML = getPrepaymentReceiptTemplate(diversionData.courtcosts, diversionData.divfee);

					topInfoSection.classList.remove('d-none');

					overviewSection.classList.remove('d-flex');
					overviewSection.classList.add('d-none');

					requiredInformationSection.classList.remove('d-flex');
					requiredInformationSection.classList.add('d-none');

					questionsSection.classList.remove('d-flex');
					questionsSection.classList.add('d-none');

					searchSection.classList.remove('d-flex');
					searchSection.classList.add('d-none');

					confirmPersonSection.classList.remove('d-flex');
					confirmPersonSection.classList.add('d-none');

					callTrafficUnitSection.classList.remove('d-flex');
					callTrafficUnitSection.classList.add('d-none');

					signAcceptSection.classList.remove('d-none');
					signAcceptSection.classList.add('d-flex');

					diversionAgreementSection.classList.remove('d-flex');
					diversionAgreementSection.classList.add('d-none');

					paymentInformationSection.classList.remove('d-flex');
					paymentInformationSection.classList.add('d-none');
				}
			});
	}
});

//Show the search section
personConfirmNoBtn.addEventListener('click', (e) => {
	e.preventDefault();

	clearTextFields();

	topInfoSection.classList.remove('d-none');

	overviewSection.classList.remove('d-flex');
	overviewSection.classList.add('d-none');

	requiredInformationSection.classList.remove('d-flex');
	requiredInformationSection.classList.add('d-none');

	questionsSection.classList.remove('d-flex');
	questionsSection.classList.add('d-none');

	searchSection.classList.remove('d-none');
	searchSection.classList.add('d-flex');

	confirmPersonSection.classList.remove('d-flex');
	confirmPersonSection.classList.add('d-none');

	callTrafficUnitSection.classList.remove('d-flex');
	callTrafficUnitSection.classList.add('d-none');

	signAcceptSection.classList.remove('d-flex');
	signAcceptSection.classList.add('d-none');

	diversionAgreementSection.classList.remove('d-flex');
	diversionAgreementSection.classList.add('d-none');

	paymentInformationSection.classList.remove('d-flex');
	paymentInformationSection.classList.add('d-none');
})

// Move back to start screen from the error screen
ackErrorBtn.addEventListener('click', (e) => {
	e.preventDefault();

	// Get the checked value of the radio buttons that allows defendant to apply for diversion

	// Yes Answers
	const isDefendantAnswerYes = isDefendantQuestionYes.checked;
	const duiAnswerYes = cdlQuestionYes.checked;
	const otherJurisdictionAnswerYes = otherJusrisdictionQuestionYes.checked;

	const isDefendantAnswerNo = isDefendantQuestionNo.checked;
	const duiAnswerNo = cdlQuestionNo.checked;
	const otherJurisdictionAnswerNo = otherJusrisdictionQuestionNo.checked;

	const noDefendantAnswer = !isDefendantAnswerYes && !isDefendantAnswerNo;
	const noDuiAnswer = !duiAnswerYes && !duiAnswerNo;
	const noOtherJurisdictionAnswer = !otherJurisdictionAnswerYes && !otherJurisdictionAnswerNo;
	const isNothingChecked = noDefendantAnswer && noDuiAnswer && noOtherJurisdictionAnswer;

	clearTextFields();

	// If the 3 questions answers do not allow applying for diversion or no answers were given, send back to main screen
	if (isDefendantAnswerNo || duiAnswerYes || otherJurisdictionAnswerYes || noDefendantAnswer || noDuiAnswer || noOtherJurisdictionAnswer) {
		topInfoSection.classList.add('d-none');

		overviewSection.classList.remove('d-none');
		overviewSection.classList.add('d-flex');

		requiredInformationSection.classList.remove('d-flex');
		requiredInformationSection.classList.add('d-none');

		questionsSection.classList.remove('d-flex');
		questionsSection.classList.add('d-none');

		searchSection.classList.remove('d-flex');
		searchSection.classList.add('d-none');

		confirmPersonSection.classList.remove('d-flex');
		confirmPersonSection.classList.add('d-none');

		callTrafficUnitSection.classList.remove('d-flex');
		callTrafficUnitSection.classList.add('d-none');

		signAcceptSection.classList.remove('d-flex');
		signAcceptSection.classList.add('d-none');

		diversionAgreementSection.classList.remove('d-flex');
		diversionAgreementSection.classList.add('d-none');
	} else {
		topInfoSection.classList.remove('d-none');

		overviewSection.classList.remove('d-flex');
		overviewSection.classList.add('d-none');

		requiredInformationSection.classList.remove('d-flex');
		requiredInformationSection.classList.add('d-none');

		questionsSection.classList.remove('d-flex');
		questionsSection.classList.add('d-none');

		searchSection.classList.remove('d-none');
		searchSection.classList.add('d-flex');

		confirmPersonSection.classList.remove('d-flex');
		confirmPersonSection.classList.add('d-none');

		callTrafficUnitSection.classList.remove('d-flex');
		callTrafficUnitSection.classList.add('d-none');

		signAcceptSection.classList.remove('d-flex');
		signAcceptSection.classList.add('d-none');

		diversionAgreementSection.classList.remove('d-flex');
		diversionAgreementSection.classList.add('d-none');
	}
});

// Invoice is acceptable, move to the agreement section
acceptFeesnBtn.addEventListener('click', (e) => {
	e.preventDefault();

	const divID = diversionID.value.split('|')[1];

	// Create the diversion record and get the data from NEW.TDA$ to get the data for the diversion agreement.
	getDiversionAgreementData(divID, "STDA").then(() => {
		const divData = diversionData.value.split('|');

		caseNo.innerText = divData[0];
		defendantName.innerText = divData[1];
		effectiveDate.innerText = divData[2];
		diversionMonths.innerText = divData[3];
		chargeList.innerText = divData[4];
		diversionFee.innerText = `$${divData[5]}`;
		courtCosts.innerText = divData[6];
		conditions.innerText = divData[7];
		lastKnownAddress.innerText = divData[8];
	});

	topInfoSection.classList.remove('d-flex');

	overviewSection.classList.remove('d-flex');
	overviewSection.classList.add('d-none');

	requiredInformationSection.classList.remove('d-flex');
	requiredInformationSection.classList.add('d-none');

	questionsSection.classList.remove('d-flex');
	questionsSection.classList.add('d-none');

	searchSection.classList.remove('d-flex');
	searchSection.classList.add('d-none');

	confirmPersonSection.classList.remove('d-flex');
	confirmPersonSection.classList.add('d-none');

	callTrafficUnitSection.classList.remove('d-flex');
	callTrafficUnitSection.classList.add('d-none');

	signAcceptSection.classList.remove('d-flex');
	signAcceptSection.classList.add('d-none');

	diversionAgreementSection.classList.remove('d-none');
	diversionAgreementSection.classList.add('d-flex');
});

// Diversion terms accepted, go to payment
acceptDiversionBtn.addEventListener('click', (e) => {
	e.preventDefault();

	const searchResults = searchData.value.split('|');

	document.getElementById('payFname').value = searchResults[6];
	document.getElementById('payMname').value = searchResults[12]
	document.getElementById('payLname').value = searchResults[5];
	document.getElementById('addr1').value = searchResults[13];
	document.getElementById('payCity').value = searchResults[14];
	document.getElementById('payState').value = searchResults[15];
	document.getElementById('payZip').value = searchResults[16];
	document.getElementById('totalBalance').innerText = `$${getTotalBalance(searchResults[10], searchResults[11])}`;

	document.getElementById('payEmail').value = document.getElementById('personEmail').value;
	document.getElementById('payCellPhone').value = document.getElementById('personPhoneNumber').value;

	topInfoSection.classList.add('d-none');

	overviewSection.classList.remove('d-flex');
	overviewSection.classList.add('d-none');

	requiredInformationSection.classList.remove('d-flex');
	requiredInformationSection.classList.add('d-none');

	questionsSection.classList.remove('d-flex');
	questionsSection.classList.add('d-none');

	searchSection.classList.remove('d-flex');
	searchSection.classList.add('d-none');

	confirmPersonSection.classList.remove('d-flex');
	confirmPersonSection.classList.add('d-none');

	callTrafficUnitSection.classList.remove('d-flex');
	callTrafficUnitSection.classList.add('d-none');

	signAcceptSection.classList.remove('d-flex');
	signAcceptSection.classList.add('d-none');

	diversionAgreementSection.classList.remove('d-flex');
	diversionAgreementSection.classList.add('d-none');

	paymentInformationSection.classList.remove('d-none');
	paymentInformationSection.classList.add('d-flex');
});

// Diversion terms were rejected, go to home section.
refuseDiversionBtn.addEventListener('click', (e) => {
	e.preventDefault();

	// Clear the hidden inputs
	document.getElementById('searchData').value = '';
	document.getElementById('diversionID').value = '';
	document.getElementById('divData').value = '';

	alert("Diversion terms were not accepted");

	setTimeout(() => {
		clearTextFields();

		topInfoSection.classList.add('d-none');

		overviewSection.classList.remove('d-none');
		overviewSection.classList.add('d-flex');

		requiredInformationSection.classList.remove('d-flex');
		requiredInformationSection.classList.add('d-none');

		questionsSection.classList.remove('d-flex');
		questionsSection.classList.add('d-none');

		searchSection.classList.remove('d-flex');
		searchSection.classList.add('d-none');

		confirmPersonSection.classList.remove('d-flex');
		confirmPersonSection.classList.add('d-none');

		callTrafficUnitSection.classList.remove('d-flex');
		callTrafficUnitSection.classList.add('d-none');

		signAcceptSection.classList.remove('d-flex');
		signAcceptSection.classList.add('d-none');

		diversionAgreementSection.classList.remove('d-flex');
		diversionAgreementSection.classList.add('d-none');

		paymentInformationSection.classList.remove('d-flex');
		paymentInformationSection.classList.add('d-none');
	}, 1000);

});

// Payment button was clicked
payBalanceBtn.addEventListener('click', (e) => {
	e.preventDefault();


	getPayURL().then((res) => { window.location.replace(res); });
})

function getPrepaymentReceiptTemplate(clerkCost, diversionCost) {
	const courtCost = parseFloat(clerkCost);
	const divFee = parseFloat(diversionCost);

	const clerkPlusDiversion = (courtCost + divFee);
	const convenienceFee = parseFloat(CONVENIENCE_FEE).toFixed(4) * clerkPlusDiversion;
	const total = (convenienceFee + clerkPlusDiversion);


	return `<div class="receipt-content">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="invoice-wrapper">
					<div class="line-items">
						<div class="headers clearfix">
							<div class="row">
								<div class="col-xs-4">Description</div>
								<div class="col-xs-5  ml-auto text-right">Amount</div>
							</div>
						</div>
						<div class="items">
							<div class="row item">
								<div class="col-xs-4 desc">
									Diversion Fee
								</div>								
								<div class="col-xs-5 ml-auto amount text-right">
									$${formatMoney(diversionCost)}
								</div>
							</div>
							<div class="row item">
								<div class="col-xs-4 desc">
									Court Costs
								</div>
								<div class="col-xs-5 ml-auto amount text-right">
									$${formatMoney(clerkCost)}
								</div>
							</div>
						</div>
						<div class="total text-right">
							<div class="field">
								Subtotal <span>$${formatMoney(clerkPlusDiversion)}</span>
							</div>
							<div class="field">
								Convenience Fee (${(CONVENIENCE_FEE * 100)}%) <span>$${formatMoney(convenienceFee)}</span>
							</div>
							<div class="field grand-total">
								Total <span>$${formatMoney(total)}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>  `;
}

function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
	try {
		decimalCount = Math.abs(decimalCount);
		decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

		const negativeSign = amount < 0 ? "-" : "";

		let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
		let j = (i.length > 3) ? i.length % 3 : 0;

		return negativeSign + (j ? i.substring(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
	} catch (e) {
		console.log(e);
	}
}

function clearTextFields() {
	// Clear the hidden inputs
	searchData.value = '';
	diversionID.value = '';
	diversionData.value = '';

	// Clear the values from the input elements
	searchCaseNumber.value = '';
	searchCitationNumber.value = '';
	searchDriversLicenseNumber.value = '';
	searchFirstName.value = '';
	searchLastName.value = '';
	confirmName.value = '';
	confirmDob.value = '';
	confirmPersonDriversLicenseNumber.value = '';
	confirmPersonDriversLicenseState.value = '';
	confirmPersonCitationNumber.value = '';
	confirmPersonCitationDate.value = '';
	personPhone.value = '';
	personEmail.value = '';

	// Clear the radio buttons
	isDefendantQuestionYes.checked = false;
	isDefendantQuestionNo.checked = false;
	cdlQuestionNo.checked = false;
	cdlQuestionYes.checked = false;
	otherJusrisdictionQuestionNo.checked = false;
	otherJusrisdictionQuestionYes.checked = false;

	// Clear the innerText from the diversion agreement fields
	caseNo.innerText = '';
	defendantName.innerText = '';
	effectiveDate.innerText = '';
	diversionMonths.innerText = '';
	chargeList.innerText = '';
	diversionFee.innerText = '';
	courtCosts.innerText = '';
	conditions.innerText = '';
	lastKnownAddress.innerText = '';
}

function getPayURL() {
	const searchResults = searchData.value.split('|');

	const cellPhone = personCellCleave.getRawValue();

	return $.ajax({
		url: reqURL + '/GetPayURL',
		type: 'get',
		contentType: '',
		dataType: 'json',
		data: {
			caseNo: searchResults[2],
			addr1: searchResults[13],
			city: searchResults[14],
			st: searchResults[15],
			zip: searchResults[16],
			firstName: searchResults[6],
			middleName: searchResults[12],
			lastName: searchResults[5],
			currentBalance: getBalanceWithoutConvenienceFee(searchResults[10], searchResults[11]),
			cellphone: cellPhone,
			email: personEmail.value,
			sessionID: sessionID.value
		}
	});
}

function createDiversionRecord(inCaseNo, inDivFee, inEmail, inPhone) {
	return $.ajax({
		url: reqURL + '/CreateDiversionRecord',
		type: 'get',
		contentType: '',
		dataType: 'json',
		data: {
			caseNo: inCaseNo,
			divFee: inDivFee,
			email: inEmail,
			phone: inPhone
		}
	});
}

function getTotalBalance(diversionFee, clerkCosts) {
	const divFee = parseFloat(diversionFee);
	const courtCosts = parseFloat(clerkCosts);
	const clerkPlusDiversion = divFee + courtCosts;

	return ((CONVENIENCE_FEE.toFixed(4) * clerkPlusDiversion) + clerkPlusDiversion).toFixed(2);
}

function getBalanceWithoutConvenienceFee(diversionFee, clerkCosts) {
	const divFee = parseFloat(diversionFee);
	const courtCosts = parseFloat(clerkCosts);

	return divFee + courtCosts;
}

function getDiversionAgreementData(diversionID, diversionType, caseNumber = '') {
	return $.ajax({
		url: reqURL + '/GetDiversionAgreementData',
		type: 'get',
		contentType: '',
		dataType: 'json',
		data: {
			caseNo: caseNumber,
			diversionId: diversionID,
			diversionType: diversionType
		},
		success: (data, status, xhr) => {
			if (data !== null) {
				diversionData.value = data;
			}
		},
		error: (jqXHR, textStatus, errorThrown) => {
			console.log(jqXHR);
			toastr.error(`The following error occurred: ${errorThrown}`);
		}
	})
}
